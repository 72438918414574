<template>
   <div class="layout-px-spacing" v-if="this.$can('admin')">
      <portal to="breadcrumb">
         <ul class="navbar-nav flex-row">
            <li>
               <div class="page-header">
                  <nav class="breadcrumb-one" aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:;">Používateľ</a></li>
                        <li class="breadcrumb-item active" aria-current="page"><span>Edit</span></li>
                     </ol>
                  </nav>
               </div>
            </li>
         </ul>
      </portal>

      <div class="row layout-spacing">
         <div id="flFormsGrid" class="col-lg-12 layout-top-spacing">
            <div class="statbox panel box box-shadow">
               <div class="panel-heading">
                  <div class="row">
                     <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>Editovať používateľa</h4>
                     </div>
                  </div>
               </div>
               <div class="panel-body">
                  <b-form @submit.prevent="submitForm" ref="form">
                     <div class="row align-items-center mb-md-4">
                        <div class="col-12 mt-md-0 mt-4">
                           <b-form-row>
                              <b-form-group label="Meno a priezvisko" class="col-md-4">
                                 <b-form-input ref="meno" :state="validateState('meno')" v-model="$v.form.meno.$model" type="text"
                                               placeholder="Meno a priezvisko"></b-form-input>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group label="Email" class="col-md-4">
                                 <b-input :state="validateState('email')" v-model="$v.form.email.$model" type="email"
                                          placeholder="Email"></b-input>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group label="Heslo" class="col-md-4">
                                 <b-input :state="validateState('heslo')" v-model="$v.form.heslo.$model" type="password"
                                          placeholder="Heslo"></b-input>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row>
                              <b-form-group class="col-md-4" label="Mesto">
                                 <b-select :state="validateState('selectedTown')" v-model="$v.form.selectedTown.$model">
                                    <b-select-option :value="null">Vybrať...</b-select-option>
                                    <b-select-option v-for="town in towns" :value="town.id" :key="town.id">
                                       {{ town.town_name }}
                                    </b-select-option>
                                 </b-select>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group class="col-md-4" label="Rola">
                                 <b-select :state="validateState('selectedRola')" v-model="$v.form.selectedRola.$model">
                                    <b-select-option :value="null">Vybrať...</b-select-option>
                                    <b-select-option v-for="rola in roles" :value="rola.id" :key="rola.id">
                                       {{ rola.name }}
                                    </b-select-option>
                                 </b-select>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group class="col-md-4" label="Jazyk">
                                 <b-select :state="validateState('language')" v-model="$v.form.language.$model">
                                    <b-select-option :value="null">Vybrať...</b-select-option>
                                    <b-select-option value="sk">Slovenčina</b-select-option>
                                    <b-select-option value="cz">Čeština</b-select-option>
                                 </b-select>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row>
                              <b-form-group class="col-12" label="Pridelení technici">
                                 <multiselect :showNoResults="false" v-model="form.value" deselectLabel="Vymazať"
                                              selectLabel="Vybrať" selectedLabel="Vybrané"
                                              tag-placeholder="Pridajte to ako novú značku" placeholder="Vyhľadať" label="name"
                                              track-by="id" :options="users" :multiple="true" :closeOnSelect="false"></multiselect>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row>
                              <b-form-checkbox
                                id="checkbox-1"
                                v-model="form.default_store">
                                 Default Sklad
                              </b-form-checkbox>
                           </b-form-row>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-12 text-right">
                           <b-button @click="$router.go(-1)" variant="cancel" class="mt-3">Zrušiť</b-button>
                           <b-button type="submit" variant="default" class="mt-3 ml-2">Uložiť</b-button>
                        </div>
                     </div>
                  </b-form>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">Nemáte oprávnění zobrazit tuto stránku.</h4>
         </div>
      </div>
   </div>
</template>

<script>
   import { validationMixin } from "vuelidate";
   import { email, required } from "vuelidate/lib/validators";
   import Multiselect from "vue-multiselect";
   import "vue-multiselect/dist/vue-multiselect.min.css";

   export default {
      name: "edit",
      components: { Multiselect },
      mixins: [validationMixin],
      validations: {
         form: {
            meno: {
               required
            },
            email: {
               required,
               email
            },
            heslo: {},
            selectedRola: {
               required
            },
            selectedTown: {
               required
            },
            language: {
               required
            }
         }
      },
      data() {
         return {
            userActual: {},
            roles: {},
            towns: {},
            users: [],
            form: {
               id: "",
               meno: "",
               email: "",
               cislo: "",
               heslo: "",
               language: "",
               selectedRola: null,
               selectedTown: null,
               default_store: false,
               value: []
            }
         };
      },
      created() {

         this.$axios.get("roles")
           .then((response) => {
              this.roles = response.data;
           });

         this.$axios.get("users?all=1")
           .then((response) => {
              this.users = response.data;
           });

         this.$axios.get("towns")
           .then((response) => {
              this.towns = response.data;
           });

         this.$axios.get("users/" + this.$route.params.pouzivatel)
           .then((response) => {
              this.userActual = response.data;
           });
         this.$v.form.$touch();
      },
      watch: {
         userActual(user) {
            this.form.meno = user.name;
            this.form.email = user.email;
            this.form.cislo = user.phone;
            this.form.language = user.language;
            this.form.selectedTown = user.town_id;
            this.form.selectedRola = user.role.id;
            this.form.value = user.technicians;
            this.form.default_store = user.default_store;
         }
      },
      methods: {
         validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
         },
         submitForm() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
               this.$toast.error("Chýbajúce údaje vo formulári");
               return;
            }

            let data = {
               name: this.form.meno,
               email: this.form.email,
               phone: this.form.cislo,
               language: this.form.language,
               town_id: this.form.selectedTown,
               rola: this.form.selectedRola,
               default_store: this.form.default_store,
               technicians_ids: this.form.value.map(item => item.id),
               _method: "PATCH"
            };

            let formData = new FormData();

            if (this.form.heslo) {
               formData.append("password", this.form.heslo);
            }

            for (let key in data) {
               formData.append(key, data[key]);
            }


            this.$axios.post("users/" + this.userActual.id, formData)
              .then((response) => {
                 this.$store.dispatch("auth/getUserData");
                 this.$router.go(- 1);
                 this.$toast.success(response.data.message);
              }).catch(error => {
               this.$toast.error(error.response.data.message);
            });
         }
      }
   };
</script>
